/**
 * FormImage
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class FormImage
 * @param {object} props - Props
 */

import React from "react";

const FormImage = (
	{
		srcs = []
	}
) => {

	return (
		<div className={`form-image-container form-image-container--${srcs.length}-items`}>
			{srcs.length > 0 && srcs.map((src, idx) => (
				<img src={src} className="form-image-container__item" key={idx} />
			))}
		</div>
	);
}

export default FormImage;
