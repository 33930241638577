/**
 * Checkbox
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Checkbox
 * @param {object} props - Props
 */

import React from "react";
import clsx from "clsx";
import {Checkbox as MuiCheckbox, makeStyles, ThemeProvider} from "@material-ui/core";
import theme from "../../../theme";
import Parser from "html-react-parser";
import * as PropTypes from "prop-types";

const useStyles = makeStyles({
    root: {
        '& input': {
            position: 'absolute',
            width: '100%',
            height: '100%'
        },
        '&:hover': {
            background: 'transparent'
        }
    },
    icon: {
        backgroundColor: 'white',
        position: 'relative',
        borderRadius: 6,
        width: 39,
        height: 39,
        boxShadow: '0 6px 22px 0 rgba(0,0,0,0.12)',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
        '&:hover': {
            boxShadow: '0 6px 22px 0 rgba(0,0,0,0.16)'
        },
        '&:before': {
            background: theme.palette.primary.main,
            position: 'absolute',
            transition: 'all .15s ease-in',
            width: 22,
            height: 22,
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%) scale(0)',
            transformOrigin: 'center center',
            borderRadius: 3
        },
        // '.checkbox-error &': {
        //     border: 'solid 1px',
        //     borderColor: theme.palette.error.main
        // }
    },
    checkedIcon: {
        '&:before': {
            content: '""',
            backgroundColor: theme.palette.primary.main,
            transform: 'translateX(-50%) translateY(-50%) scale(1)',
        },
    },
});

const Checkbox = ({label, error = false, helperText, ...props}) => {
    const classes = useStyles();
    const id = props.id || Math.floor(Math.random() * 100);

    return (
        <>
            {error && <p className="custom-checkbox-error">{helperText}</p>}
            <div className="custom-checkbox-container">
                <ThemeProvider theme={theme}>
                    <MuiCheckbox
                        id={id}
                        className={classes.root + (error ? ' checkbox-error' : '')}
                        disableRipple
                        color="default"
                        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
                        icon={<span className={classes.icon}/>}
                        inputProps={{'aria-label': 'decorative checkbox'}}
                        {...props}
                    />
                </ThemeProvider>
                <label htmlFor={id} className="custom-checkbox-container__label">
                    {props.required && '*'}{label && Parser(label)}
                </label>
            </div>
        </>
    );
}

export default Checkbox;
