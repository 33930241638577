/**
 * TextField
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class TextField
 * @param {object} props - Props
 */

import React, { Fragment } from "react";
import { InputAdornment, ThemeProvider } from '@material-ui/core';
import { CustomTextField } from "./CustomTextField";
import Icon from "../icon";
import theme from '../../../theme';
import Autocomplete from '@material-ui/lab/Autocomplete';

const TextField = (
    {
        items = [],
        grouped = false,
        icon: InputIcon,
        ...props
    }) => {
    return <ThemeProvider theme={theme}>
        {
            (props.autocomplete && !grouped && items.length) ?
                <Autocomplete
                    className={'autocomplete'}
                    options={items}
                    getOptionLabel={(option) => option.label}
                    fullWidth
                    forcePopupIcon={true}
                    popupIcon={<Icon
                        color="blue"
                        name="arrow-down"
                    />}
                    renderInput={(params) =>
                        <CustomTextField
                            fullWidth
                            variant="outlined"
                            label={props.defaultValue}
                            {...params}
                        >
                        </CustomTextField>
                    }
                />
                : <CustomTextField
                    fullWidth
                    SelectProps={{
                        native: true,
                        IconComponent: () => <Icon className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined"
                            color="blue" name="arrow-down" />,
                    }}
                    variant="outlined"
                    InputProps={{
                        endAdornment: React.isValidElement(InputIcon) && <InputAdornment position="end">{InputIcon}</InputAdornment>
                    }}
                    {...props} >

                    {(props.select && grouped && items.length) && items.map((item, idx) => (
                        <optgroup key={idx} label={item.label}>
                            {item.items.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </optgroup>
                    ))}

                    {(props.select && !grouped && items.length) && items.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </CustomTextField>
        }


    </ThemeProvider>
}

export default TextField;
