import React from "react"
import Seo from "../app/services/seo"
import Layout from "../app/layouts/default"
import ContactForm from "../app/modules/contact-form";
import AccordionDetailsTable from "../app/components/accordion-details-table";
import CustomAccordion from "../app/components/custom-accordion";

const breadcrumbs = [
    {
        link: '/',
        label: 'Home'
    },
    {
        label: 'Contattaci'
    }

];


const accordionTableData = {
    headers: [{
        label: 'Modello'
    }, {
        label: 'Codice prodotto'
    }, {
        label: 'Lingua'
    }, {
        empty: true,
        label: 'Download'
    }],
    items: [
        [{
            field: 'Modello',
            value: 'AQUA 80F/1'
        }, {
            field: 'Codice prodotto',
            value: '31004081'
        }, {
            field: 'Lingua',
            value: 'IT'
        }, {
            field: 'Modello',
            value: 'AQUA 80F/1'
        }, {
            field: 'Codice prodotto',
            value: '31004081'
        }, {
            field: 'Lingua',
            value: 'IT'
        }, {
            field: 'Download',
            icon: 'symbols-download',
            link: 'http://www.africau.edu/images/default/sample.pdf'
        }],
        [{
            field: 'Modello',
            value: 'AQUA 80F/1'
        }, {
            field: 'Codice prodotto',
            value: '31004081'
        }, {
            field: 'Lingua',
            value: 'IT'
        }, {
            field: 'Modello',
            value: 'AQUA 80F/1'
        }, {
            field: 'Codice prodotto',
            value: '31004081'
        }, {
            field: 'Lingua',
            value: 'IT'
        }, {
            field: 'Download',
            icon: 'symbols-download',
            link: 'http://www.africau.edu/images/default/sample.pdf'
        }]
    ]
};

const ContactsPage = () => {
    return (
        <Layout noMarginFooter={true} breadcrumbs={breadcrumbs}>
            <Seo title="About Us page" keywords={[`gatsby`, `application`, `react`]}/>
            <div className="contacts-container">
                <ContactForm
                    introTitle="Contattaci"
                    introText="Hai bisogno di informazioni commerciali sui nostri prodotti o vuoi entrare in contatto con Candy? Compila il form selezionando il motivo del contatto, se invece hai bisogno di supporto tecnico, contatta il <a href='#'>servizio di assistenza</a> e ti daremo l'aiuto di cui hai bisogno."
                    onSubmit={console.log}
                />
                <div className="container" style={{padding: '30px 0', maxWidth: '70%'}}>
                    <CustomAccordion title="User Manual">
                        <AccordionDetailsTable headers={accordionTableData.headers} items={accordionTableData.items}/>
                    </CustomAccordion>
                </div>
            </div>
        </Layout>
    )
};

export default ContactsPage;
