import { withStyles, TextField } from '@material-ui/core';
import theme from '../../../theme';

export const CustomTextField = withStyles({
  root: {
    // fontFamily: theme.typography.fontFamily,
    border: 'none',
    // width: '100%',
    '& label': {
      transform: 'translate(25px, 22px) scale(1)',
      color: theme.palette.secondary.main,
      fontSize: 18
    },
    '& label.Mui-focused': {
      color: theme.palette.primary.main
    },
    '& label.Mui-focused, & label.MuiInputLabel-shrink': {
      // color: 'green',
      // paddingLeft: 30,
      transform: 'translate(25px, 8px) scale(0.75)',
      fontSize: 17
    },
    '& .MuiInputAdornment-root .icon': {
      fontSize: 32
    },
    '& .MuiOutlinedInput-root': {
      boxShadow: '0 6px 22px 0 rgba(0,0,0,0.09)',
      borderRadius: '30px',
      '& fieldset': {
        // borderColor: 'red',
        border: 'none',
      },
      // '&:hover fieldset': {
      //     borderColor: 'yellow',
      // },
      // '&.Mui-focused fieldset': {
      //     borderColor: 'green',
      // },
      '& input, & select': {
        color: theme.palette.text.primary,
        padding: '23.1px 25px 13.2px',
        width: '100%',
        fontSize: 18
      },
      '& textarea:not([aria-hidden="true"])': {
        padding: '5px 10px'
      },
      '& .MuiAutocomplete-input:first-child': {
        paddingLeft: "14.3px",
        top: "5px",
        position: "relative"
      },
    },
    '& .Mui-focused select': {
      background: 'transparent'
    },
    '& .MuiSelect-iconOutlined': {
      right: 12,
      fontSize: 26
    },
    '& .MuiFormHelperText-root': {
      fontSize: 14,
      margin: '3px 26px'
    }
  }
})(TextField);