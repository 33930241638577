/**
 * Alert
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Alert
 * @param {object} props - Props
 */

import React from "react";
import {ThemeProvider} from '@material-ui/core';
import theme from '../../../theme';
import {Alert as MuiAlert, AlertTitle} from '@material-ui/lab';

const Alert = (
    {
        title,
        severity,
        label,
        align = 'center'
    }
) => {

    return (
        <ThemeProvider theme={theme}>
            <MuiAlert icon={false} severity={severity} className={`custom-alert text-${align}`}>
                {title && <AlertTitle>{title}</AlertTitle>}
                {label}
            </MuiAlert>
        </ThemeProvider>
    );
}

export default Alert;
