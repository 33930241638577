/**
 * Radio
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Radio
 * @param {object} props - Props
 */

import React from "react";
import {makeStyles} from "@material-ui/core";
import {Radio as MuiRadio} from '@material-ui/core';
import clsx from "clsx";
import theme from '../../../theme';

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 25,
        height: 25,
        boxShadow: '0 6px 22px 0 rgba(0,0,0,0.12)',
        backgroundColor: 'white',
        // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            // backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        position: 'relative',
        backgroundColor: 'white',
        // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            position: 'absolute',
            width: 15,
            height: 15,
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%) scale(1)',
            transformOrigin: 'center center',
            borderRadius: '50%',
            backgroundColor: theme.palette.primary.main,
            content: '""',
            // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        },
        'input:hover ~ &': {
            // backgroundColor: '#106ba3',
        },
    },
});

const Radio = (props) => {
    const classes = useStyles();

    return (
        <MuiRadio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
            icon={<span className={classes.icon}/>}
            {...props}
        />
    );
}

export default Radio;
