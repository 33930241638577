/**
 * FormDivider
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class FormDivider
 * @param {object} props - Props
 */

import React from "react";

const FormDivider = () => {

	return (
		<hr className="form-divider" />
	);
}

export default FormDivider;
