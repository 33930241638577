/**
 * FormItemRadio
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class FormItemRadio
 * @param {object} props - Props
 */

import React, {useState} from "react";
import {FormControl, FormControlLabel, FormLabel, RadioGroup, ThemeProvider} from "@material-ui/core";
import {default as CustomRadio} from "../../atoms/radio";
import theme from "../../../theme";

const FormItemRadio = (
    {
        name,
        defaultValue,
        items = [],
        children,
        // radioProps = {},
        onChange: onChangeCallback,
        error = false,
        helperText,
        ...props
    }
) => {

    const [value, setValue] = useState(defaultValue);
    const handleChange = event => {
        setValue(event.target.value);
        onChangeCallback && onChangeCallback(event, event.target.value);
    };

    return (
        <>
            <FormControl component="fieldset" className='custom-radio-group'>
                <ThemeProvider theme={theme}>
                    <FormLabel component="legend"
                               className="custom-radio-group__label">{props.required && '*'}{children}</FormLabel>
                    {error && <p className="custom-radio-group-error">{helperText}</p>}
                    <RadioGroup row aria-label={name} name={name} value={value} onChange={handleChange}
                                className="custom-radio-group__radios">
                        {items.length > 0 && items.map(({label, value}) => (
                            <FormControlLabel className="custom-radio-group__radio" key={value} value={value}
                                              control={<CustomRadio {...props}/>} label={label}/>
                        ))}
                    </RadioGroup>
                </ThemeProvider>
            </FormControl>
        </>
    );
}

export default FormItemRadio;
