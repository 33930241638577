/**
 * ContactForm
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passing data to them.
 * @class ContactForm
 * @param {object} props - Props
 */

import React, {useState} from "react";
import EditorialIntro from "../../components/editorial";
import Parser from "html-react-parser";
import Button from "../../atoms/button";
import TextField from "../../atoms/text-field";
import Checkbox from "../../atoms/checkbox";
import FormItemRadio from "../../components/form-item-radio";
import {Link} from '../../atoms/link';
import Alert from "../../atoms/alert";
import FormImage from "../../atoms/form-image";
import FormDivider from "../../atoms/form-divider";

const ContactForm = (
    {
        introTitle,
        introText,
        introLabel,
        introLink,
        onSubmit: onSubmitCallback
    }
) => {

    const requestTypes = [
        {label: 'Informazioni commerciali', value: 'commercial'},
        {label: 'Informazioni commerciali1', value: 'commercial1'},
        {label: 'Informazioni commerciali2', value: 'commercial2'},
    ];

    const [formValues, setFormValues] = useState({
        requestType: 'commercial',
        name: 'Mario',
        surname: '',
        email: '',
        address: '',
        city: '',
        province: '',
        zip: '',
        telephone: '',
        note: '',
        privacy: false,
        marketing: 'no',
        marketing2: 'no',
        recaptcha: false
    });

    const handleChange = (event) => {
        const type = event.target.type;
        setFormValues({
            ...formValues,
            [event.target.name]: type === 'checkbox' ? event.target.checked : event.target.value
        });
    };

    const handleSubmit = () => {
        onSubmitCallback && onSubmitCallback(formValues);
        console.log('Submit Form!!', formValues);
    };

    return (
        <div>
            {
                (introTitle || introText) &&
                <EditorialIntro>
                    <div className="editorial-intro">
                        {introTitle && <h2>{introTitle}</h2>}
                        <div className="p">{introText && Parser(introText)}</div>
                        {introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink}/>}
                    </div>
                </EditorialIntro>
            }
            <div className="contact-form-container">
                <span className="contact-form-container__help-text">* campi obbligatori</span>
                <TextField label="Tipo di richiesta" select items={requestTypes} name="requestType"
                           onChange={handleChange} defaultValue={formValues.requestType}/>
                <Alert
                    align="left"
                    title="Example Title"
                    severity="info"
                    label="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis cumque et fugit iure maiores neque nisi repellat saepe veritatis voluptatum?"
                />
                <FormImage srcs={[
                    "https://prod-cdn-candy-hoover.haier.stormreply.com/candy/ext-resources-blog/images/libretto-lavatrice-front.jpg",
                    "https://prod-cdn-candy-hoover.haier.stormreply.com/candy/ext-resources-blog/images/libretto-label.jpg"
                ]} />
                <FormDivider />
                <TextField label="Nome" name="name" defaultValue={formValues.name}
                           onChange={handleChange}/>
                <TextField label="Cognome" name="surname" defaultValue={formValues.surname}
                           onChange={handleChange}/>
                <TextField label="Email" name="email" defaultValue={formValues.email}
                           onChange={handleChange}/>
                <TextField label="Indirizzo e n. civico" name="address" defaultValue={formValues.address}
                           onChange={handleChange}/>
                <TextField label="Città" name="city" defaultValue={formValues.city}
                           onChange={handleChange}/>
                <div className="col-2">
                    <TextField label="Provincia" name="province" defaultValue={formValues.province}
                               onChange={handleChange}/>
                    <TextField label="CAP" name="zip" defaultValue={formValues.zip}
                               onChange={handleChange}/>
                </div>
                <TextField label="Telefono" name="telephone" defaultValue={formValues.telephone}
                           onChange={handleChange}/>
                <TextField label="Inserisci nota" multiline rows={6} name="note" defaultValue={formValues.note}
                           onChange={handleChange}/>

                <Checkbox
                    id="privacy"
                    name="privacy"
                    checked={formValues.privacy}
                    onChange={handleChange}
                    required
                    label="Dichiaro di aver preso visione dell'<a href='#'>informativa privacy</a> di Candy Hoover Group Srl"
                />

                <FormItemRadio
                    name="marketing"
                    defaultValue={formValues.marketing}
                    onChange={handleChange}
                    items={[{label: 'Si', value: 'yes'}, {label: 'No', value: 'no'}]}
                >
                    Voglio ricevere comunicazioni commerciali e promozionali, via e-mail, SMS, o al telefono, anche
                    personalizzate, come indicato nell'<Link to="#">informativa</Link>.
                </FormItemRadio>

                <FormItemRadio
                    name="marketing2"
                    defaultValue={formValues.marketing2}
                    onChange={handleChange}
                    items={[{label: 'Si', value: 'yes'}, {label: 'No', value: 'no'}]}
                >
                    Voglio condividere i miei dati con i partner commerciali di Candy Hoover Group che operano
                    nell’ambito dell'assistenza e offerta di prodotti assicurativi per proprie finalità di marketing
                    secondo quanto indicato nell'informativa.
                </FormItemRadio>

                <p>TODO: Recaptcha V2 <br/><br/></p>

                <Button
                    className="secondary sm _15"
                    color="blue"
                    onClick={handleSubmit}
                    label="Invia"
                />
            </div>
        </div>
    );
}

export default ContactForm;

